import React from 'react'
import Footer from '../footer/Footer'
import TransitionPage from '../TransitionPage'
import { FaArrowDown } from "react-icons/fa";
import './PriceStyles.css'
import { Link } from 'react-router-dom';

const Price = () => {

    return (
        <>
            <TransitionPage>
                <div className='price'>

                    <div id='price-header'>
                        <h1 className='title'>Prices</h1>
                        <div className='price-desc'>

                            <h2>If you have any questions please check out the <Link to="/rules"><span className='link-blue'>rules</span></Link> or <Link to="/contact"><span className='link-blue'>contact</span></Link> us!</h2>
                            <h3 className='buyout'>Want to rent out Keiki Kingdom for your next celebration?</h3>
                            <h3 className='buyout'>Please <Link to="/contact"><span className='link-blue'>contact</span></Link> us for buy-out events!</h3>
                        </div>
                        <div className="container">
                            <h2><FaArrowDown className='arrow-down' /> Check Prices Below <FaArrowDown className='arrow-down' /></h2>
                            <div className='media-arrow'><FaArrowDown className='arrow-down' /> <FaArrowDown className='arrow-down' /></div>
                            <div className="content">
                                <button onClick={() => window.location.replace("/price#admission")}>Admission</button>
                                <button onClick={() => window.location.replace("/price#party")}>Party Rooms</button>

                            </div>
                        </div>
                    </div>


                    <div id="admission">
                        <div className="container">
                            <h1 className='title'>General Admissions</h1>
                            <h3>Parents must accompany their children!</h3>
                            <p className='notice'>Membership and access are not available during private events and maintenance days<br/>Please check our <Link to="/calendar"><span className='link-blue'>calendar</span></Link> for updates!</p>
                            <div className="content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Children</th>
                                            <th>Adult</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='td1'><strong>2 Hours</strong></td>
                                            <td>$22</td>
                                            <td>$5</td>
                                        </tr>
                                        <tr>
                                            <td className='td1'><strong>3 Hours</strong></td>
                                            <td>$32</td>
                                            <td>$5</td>
                                        </tr>
                                        <tr>
                                            <td className='td1'><strong>Monthly Membership</strong><p className='note'><strong>Monday to Friday Only<br />No Holidays/Weekends</strong></p>
                                            </td>
                                            <td>$150</td>
                                            <td>$30</td>
                                        </tr>
                                        <tr>
                                            <td className='td1'><strong>Kingdom Membership</strong><p className='note'><strong>No blockout dates<br />Includes Holidays/Weekends</strong></p>
                                            </td>
                                            <td>$200</td>
                                            <td>$30</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='details'>

                                    <p><strong className='info'>Please Note:</strong> </p>
                                    <p>Children: 10 months to 13 years old</p>
                                    <p>Infant must remain in a safety carrier accompanied by an adult AT ALL TIMES</p>
                                    <p>Teens 14 years and above are not allowed to jump or play on certain equipment.</p>
                                    <p className='miniDetails'>Reason for this is because the equipment is designed for younger children and have weight or height restrictions to ensure safety.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />


                    <div id="party">
                        <div className="container">
                            <h1 className='title'>Reserve a Party Room!</h1>
                            <p>Come book a private party room! It's the perfect place to celebrate birthdays and events for your children!</p>
                            <div className="content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Mon - Thur</th>
                                            <th>Room</th>
                                            <th>Children</th>
                                            <th>Adult</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td className='td1'><strong>3 Hours</strong></td>
                                            <td>$300</td>
                                            <td>$32</td>
                                            <td>$5</td>
                                        </tr>
                                        <tr>
                                            <td className='td1'><strong>4 Hours</strong></td>
                                            <td>$380</td>
                                            <td>$42</td>
                                            <td>$5</td>
                                        </tr>
                                        <tr>
                                            <td className='td1'><strong>5 Hours</strong></td>
                                            <td>$460</td>
                                            <td>$52</td>
                                            <td>$5</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Fri - Sun <br />(Holidays)</th>
                                            <th>Room</th>
                                            <th>Children</th>
                                            <th>Adult</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td className='td1'><strong>3 Hours</strong></td>
                                            <td>$400</td>
                                            <td>$32</td>
                                            <td>$5</td>
                                        </tr>
                                        <tr>
                                            <td className='td1'><strong>4 Hours</strong></td>
                                            <td>$500</td>
                                            <td>$42</td>
                                            <td>$5</td>
                                        </tr>
                                        <tr>
                                            <td className='td1'><strong>5 Hours</strong></td>
                                            <td>$600</td>
                                            <td>$52</td>
                                            <td>$5</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='details'>

                                    <p><strong className='info'>VIP Service Package for $200</strong></p>
                                    <p>Private Party Assistant</p>
                                    <p>Meet and Greet with "Keiki" Mascot</p>
                                    <p>Party Photo with Keiki</p>
                                    <p>Singing & Dancing Party with Surprise Gifts</p>
                                    <br />
                                    <p><strong className='info'>Please Note:</strong> </p>
                                    <p>Any cancellation must be made 14 days before starting date.</p>
                                    <p>To find out more about party packages please <Link to="/contact"><span className='link-blue'>contact</span></Link> us</p>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </TransitionPage>
        </>

    )
}

export default Price
